import React, { useState, useContext, useEffect } from "react";
import Location from "../utils/Location";
import ApplicationContext from "../../contexts/ApplicationContext";
import Storage from "../utils/Storage";
import config from "../../config";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import mlb_logo from "../../images/mlb_logo.png";

const Locate = () => {
  const StorageInstance = new Storage();
  const locationInstance = new Location();
  const [location, setLocation] = useState({
    lat: config.useStorage ? StorageInstance.getItem("userLat", 0) : 0,
    lon: config.useStorage ? StorageInstance.getItem("userLon", 0) : 0
  });
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useContext(ApplicationContext);

  useEffect(() => {
    let StorageInstance = new Storage();
    let sum = location.lat + location.lon;
    if (sum !== 0) {
      if (config.useStorage) {
        StorageInstance.setItem("userLat", location.lat);
        StorageInstance.setItem("userLon", location.lon);
      }
    }
  }, [location]);

  function handleLocate() {
    //staging
    setLoading(true);
    // setLocation({ lat: 44.981683, lon: -93.278305 });
    // setLocation({ lat: 0, lon: -1 });
    // setApplication({
    //   ...application,
    //   // location: { lat: 44.981683, lon: -93.268305 },
    //   location: { lat: 0, lon: -1 },
    //   page: "team"
    // });

    //live
    locationInstance.getLocation(pos => {
      const { latitude, longitude } = pos.coords;
      setLocation({ lat: latitude, lon: longitude });
      setApplication({
        ...application,
        location: { lat: latitude, lon: longitude },
        page: "team"
      });
    });
  }

  function renderObjects() {
    if (loading) {
      return <h3 style={__PLACEHOLD_STYLE__}>finding your location...</h3>;
    } else {
      return (
        <div className="locate-container">
          <Grid container direction="column" justify="center" alignItems="center" className="locate-content">
            <Grid item>
              <img src={mlb_logo} className="locate-mlb-logo" alt="logo" />
            </Grid>
            <Grid item>
              <p className="locate-text proxima-bold">
                KNOCK ALS OUT
                <br />
                OF THE PARK
              </p>
            </Grid>
            <Grid item>
              <Button className="bold locate-button" onClick={handleLocate}>
                Start
              </Button>
            </Grid>
          </Grid>
          <div className="disclaimer-footer">
            <p>MLB trademarks and copyrights are used with permission of Major League Baseball. Visit MLB.com.</p>
          </div>
        </div>
      );
    }
  }

  var __PLACEHOLD_STYLE__ = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    fontSize: "18pt",
    textAlign: "center",
    display: "block",
    margin: 0
  };

  return renderObjects();
};

export default Locate;
