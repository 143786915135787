class Storage {
  getItem(key, fallback) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : fallback;
  }

  setItem(key, value) {
    return localStorage.setItem(key, value);
  }

  removeItems(keys, cb) {
    keys.forEach(key => {
      localStorage.removeItem(key);
    });
    cb();
  }

  logStorage() {
    console.log(localStorage);
  }
}

export default Storage;
