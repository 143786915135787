import React from "react";
import Generator from "./components/Generator";
import BackgroundGenerator from "./components/BackgroundGenerator";
import Homepage from "./components/routes/Homepage";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import { Router } from "@reach/router";
import "./App.scss";

const App = () => {
  return (
    <ErrorBoundary>
      <div className="App">
        <Router>
          <Homepage path="/" />
          <Generator path="/generator" />
          <BackgroundGenerator path="/zoom" />
        </Router>
      </div>
    </ErrorBoundary>
  );
};

export default App;
