import React, { useState } from "react";
import Carousel from "./Carousel";
import Instructions from "./Instructions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import mlb_logo from "../../images/mlb_logo.png";
import header_logo from "../../images/header_logo.png";
import generator_image from "../../images/generator_image2.png";
import Modal from "@material-ui/core/Modal";

import { navigate } from "@reach/router";

const Homepage = () => {
  const [modal, setModal] = useState(false);

  function handleNavigate(to) {
    navigate(to);
  }

  function handlePageChange() {
    window.open("http://web.alsa.org/site/Donation2?df_id=39567&mfc_pref=T&39567.donation=form1");
  }

  function handleOpen() {
    setModal(true);
  }

  function handleClose() {
    setModal(false);
  }

  function closeModal() {
    setModal(false);
  }

  function renderModal() {
    return (
      <Modal
        aria-labelledby="instruction-modal"
        aria-describedby="instruction-modal-description"
        open={modal}
        onClose={handleClose}
        style={{ backgroundColor: "#062c3f" }}
        className="instruction-modal"
      >
        <div>
          <Instructions />
          <Button className="close-button" onClick={closeModal}>
            X
          </Button>
        </div>
      </Modal>
    );
  }

  function play() {
    navigate("generator");
  }

  return (
    <div id="homepage">
      <Grid container direction="column" justify="center">
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center" className={"header"}>
            <img src={header_logo} alt="logo" style={{ width: "70%" }} />
          </Grid>
        </Grid>
        <Grid item>
          <Carousel />
        </Grid>
        <Grid item style={{ width: "100%" }} container direction="column" justify="center" alignItems="center">
          <Grid item>
            <h1 className="blue bold home-headline">
              <i>HEY DODGERS AND RAYS FANS:</i> TO SUPPORT YOUR TEAM, AND THE ALS ASSOCIATION, DISPLAY THE DISTANCE
              BETWEEN YOU AND HOME PLATE AT THE WORLD SERIES – WITH YOUR OWN OUTFIELD WALL ZOOM BACKGROUND.
            </h1>
          </Grid>
          <Grid item>
            <Button className="button donate-button bold" onClick={() => handleNavigate("zoom")}>
              Get Zoom Background
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ height: 20 }}></Grid>
        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ overflow: "hidden" }}
            spacing={3}
          >
            <Grid item>
              <Button variant="contained" className="button donate-button bold" onClick={handlePageChange}>
                Donate
              </Button>
            </Grid>
            <Grid item>
              <center>
                <img src={generator_image} alt="generator" style={{ height: "40vh" }} />
                <h1 className="instruction-headline blue bold">HOME RUN SELFIE GENERATOR</h1>
                <p className="instruction-paragraph">
                  Measure the distance between you and your favorite team&apos;s home plate
                </p>
              </center>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={play} className="button play-button bold">
                Click to Play
              </Button>
            </Grid>

            <Grid item>
              <h1 className="instruction-headline blue bold">HOW IT WORKS</h1>
            </Grid>
            <Grid item>
              <ul className="instructions light">
                <li>Generate your distance number</li>
                <li>Snap a selfie next to a wall</li>
                <li>Place number on photo</li>
                <li>Save, share -- and even donate</li>
              </ul>
            </Grid>
            <Grid item>
              <Button onClick={handleOpen} variant="contained" className="button tutorial-button bold">
                WATCH TUTORIAL
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <iframe
              style={{ width: "80%", marginLeft: "10%", marginTop: "20pt", height: "50vw", maxHeight: "500px" }}
              src="https://www.youtube.com/embed/K5fXBrs98BE"
              frameBorder="0"
              title="als-video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
        </Grid>
      </Grid>
      {renderModal()}
    </div>
  );
};

export default Homepage;
