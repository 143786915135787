import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import mlb_logo from "../../images/mlb_logo.png";
import ApplicationContext from "../../contexts/ApplicationContext";
import Input from "@material-ui/core/Input";
import Location from "../utils/Location";

const ImageCapture = () => {
  const [application, setApplication] = useContext(ApplicationContext);
  const [image, setImage] = useState(new File([], "defaultFile"));
  const locationInstance = Location();

  // useEffect(() => {
  //   console.log("captureload");
  //   return () => {
  //     setImage(new File([], "defaultFile"));
  //     // setDistance(Math.round(dist) * 5280);
  //     // setApplication({ ...application, distance: Math.round(dist) * 5280 });
  //   };
  // }, []);

  const onFileChange = e => {
    e.preventDefault();
    setApplication({ ...application, page: "edit", image: e.target.files[0] });
  };

  return (
    <div className="file-form-container">
      <Grid container direction="column" justify="space-between" alignItems="stretch" style={{ height: "100%" }}>
        <Grid item className={"header"}>
          <Grid container direction="column" justify="center" alignItems="center">
            <img src={mlb_logo} alt="logo" style={{ height: "8vh", padding: "1vh" }} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item>
              <div className="tilt-animation"></div>
            </Grid>
            <Grid item>
              <h1 className="blue capture-headline proxima-bold">UPLOAD A SELFIE</h1>
            </Grid>
            <Grid item>
              <div className="file-input-wrapper">
                <Button className="button bold capture-button">Take Photo</Button>
                <input type="file" accept=".jpg" capture="user" onChange={onFileChange} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item></Grid>
        <div className="disclaimer-footer black">
          <p>MLB trademarks and copyrights are used with permission of Major League Baseball. Visit MLB.com.</p>
        </div>
      </Grid>
    </div>
  );
};

export default ImageCapture;
