import React, { useContext, useState } from "react";
import ApplicationContext from "../../contexts/ApplicationContext";
import Grid from "@material-ui/core/Grid";
import mlb_logo_red from "../../images/mlb_logo_red.png";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Location from "../utils/Location";
import fb_logo from "../../images/fb_logo.png";
import twitter_logo from "../../images/twitter_logo.png";
import copy_link_logo from "../../images/copy_link_logo.png";
import APIHelper from "../utils/APIHelper";

const Confirm = () => {
  const [application] = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [popoverStatus, setPopoverStatus] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  function handleDonate() {
    let distance = Math.round(
      locationInstance.calculateDistance(
        application.location.lat,
        application.location.lon,
        application.team.lat,
        application.team.lon
      )
    );

    window.open(
      "http://web.alsa.org/site/Donation2?df_id=39451&mfc_pref=T&39451.donation=form1&s_mlbdistance=" + distance
    );
  }

  function shareTwitter() {
    setLoading(true);
    let APIHelperInstance = new APIHelper();
    APIHelperInstance.post(application.completeImageURI).then(function(response) {
      window.location =
        "https://twitter.com/intent/tweet?url=" +
        encodeURI("https://donateals.org/share.php?image=" + response.name) +
        "&text=Try our Home Run Selfie Generator today";
    });
  }

  function copyLink(event) {
    let copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy", false);
    setPopoverStatus(true);
    setAnchorElement(event.currentTarget);
  }

  function closePopover() {
    setPopoverStatus(false);
  }

  const locationInstance = new Location();

  function shareFacebook() {
    setLoading(true);
    let APIHelperInstance = new APIHelper();
    APIHelperInstance.post(application.completeImageURI)
      .then(function(response) {
        window.location =
          "https://www.facebook.com/dialog/share?app_id=542407399654824&display=popup&redirect_uri=https://donateals.org&href=" +
          encodeURI("https://donateals.org/share.php?image=" + response.name);
      })
      .catch(err => console.log(err));
  }

  function renderShareButtons() {
    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <Grid container direction="row">
          <Grid item xs={4}>
            <Button onClick={shareFacebook}>
              <img src={fb_logo} alt="fblogo" className="fb-logo" />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={shareTwitter}>
              <img src={twitter_logo} alt="twlogo" className="twitter-logo" />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={copyLink}>
              <img src={copy_link_logo} alt="linklogo" className="twitter-logo" />
            </Button>
            <Popover
              open={popoverStatus}
              anchorEl={anchorElement}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div style={{ padding: 10 }}>Link Copied</div>
            </Popover>
          </Grid>
        </Grid>
      );
    }
  }
  return (
    <div style={{ overflowX: "hidden" }}>
      <Grid container direction="column" justify="flex-start" alignItems="center" style={{ overflow: "hidden" }}>
        <Grid item>
          <h1 className="confirm-copy">Thanks. With your help we&apos;ll #knockALSoutofthepark</h1>
        </Grid>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={mlb_logo_red} alt="logo" style={{ height: "8vh", padding: "1vh" }} />
        </Grid>
        <Grid item xs={12}>
          <img id="complete-image" alt="complete" src={application.completeImageURI} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Button className="button donate-button bold" onClick={handleDonate}>
            Donate{" $"}
            {Math.round(
              locationInstance.calculateDistance(
                application.location.lat,
                application.location.lon,
                application.team.lat,
                application.team.lon
              )
            )
              .toString()
              .slice(0, 4) / 100}
          </Button>
          <p className="proxima-bold donate-text">(Suggested amount only. Give what you wish.)</p>
        </Grid>
        <Grid item xs={6}>
          {renderShareButtons()}
        </Grid>
        <input style={{ opacity: 0 }} readOnly value="https://donateals.org" id="copyText" />
      </Grid>
      <div className="disclaimer-inline black">
        <p>MLB trademarks and copyrights are used with permission of Major League Baseball. Visit MLB.com.</p>
      </div>
    </div>
  );
};

export default Confirm;
