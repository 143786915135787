import React, { useState, useEffect, useRef } from "react";
import Location from "./utils/Location";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import { fabric } from "fabric-with-gestures";

import numeral from "numeral";
import header_logo from "../images/header_logo.png";
import { number } from "prop-types";

const stadiumLocation = {
  longitude: 32.751589,
  latitude: -97.082998
};

function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const BackgroundGenerator = () => {
  const [distance, setDistance] = useState(0);
  const [allowDistance, setAllowDistance] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const tb1BackgroundCanvas = useRef(null);
  const tb2BackgroundCanvas = useRef(null);
  const la1BackgroundCanvas = useRef(null);
  const la2BackgroundCanvas = useRef(null);

  const [tb1ImageUrl, setTb1ImageUrl] = useState();
  const [tb2ImageUrl, setTb2ImageUrl] = useState();
  const [la1ImageUrl, setLa1ImageUrl] = useState();
  const [la2ImageUrl, setLa2ImageUrl] = useState();

  const [popoverStatus, setPopoverStatus] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  useEffect(function() {
    let LocationAPI = new Location();
    LocationAPI.getLocation(function(loc) {
      const { latitude, longitude } = loc.coords;
      let distance = LocationAPI.calculateDistance(
        latitude,
        longitude,
        stadiumLocation.longitude,
        stadiumLocation.latitude
      );
      setDistance(distance);
    });
  }, []);

  useEffect(function() {
    generateBackground("/img/tb_office_blank.jpg", distance, tb1BackgroundCanvas, dataurl => {
      setTb1ImageUrl(dataurl);
    });
    generateBackground("/img/tb_seats_blank.jpg", distance, tb2BackgroundCanvas, dataurl => {
      setTb2ImageUrl(dataurl);
    });
    generateBackground("/img/la_office_blank.jpg", distance, la1BackgroundCanvas, dataurl => {
      setLa1ImageUrl(dataurl);
    });
    generateBackground("/img/la_seats_blank.jpg", distance, la2BackgroundCanvas, dataurl => {
      setLa2ImageUrl(dataurl);
    });
  });

  function shareTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=Check%20out%20my%20new%20zoom%20background%20https://donateals.org"
    );
  }

  function copyLink(event) {
    let copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy", false);
    setPopoverStatus(true);
    setAnchorElement(event.currentTarget);
  }

  function closePopover() {
    setPopoverStatus(false);
  }

  // const locationInstance = new Location();

  function shareFacebook() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.donateals.org%2F&amp;src=sdkpreparse",
      "something",
      "width=600,height=600"
    );
  }

  function handleDonate() {
    window.open(
      "http://web.alsa.org/site/Donation2?df_id=39451&mfc_pref=T&39451.donation=form1&s_mlbdistance=" + distance
    );
  }

  function generateBackground(image, d, canvasRef, cb) {
    if (canvasRef.current !== null) {
      let zoombgCanvas = new fabric.Canvas(canvasRef.current, {
        preserveObjectStacking: true,
        containerClass: "bg-generator-canvas"
      });
      zoombgCanvas.setWidth(1920);
      zoombgCanvas.setHeight(1080);

      let textConfig = {
        fontSize: 100,
        fontFamily: "Helvetica",
        fill: "white",
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true
      };

      // d = 9000000;

      let numberInstance;

      if (d > 99999999) {
        numberInstance = new fabric.Text(
          numeral(d)
            .format("0.00a")
            .toUpperCase(),
          { ...textConfig, originX: "center", left: 1620, top: 440 }
        );
      } else {
        numberInstance = new fabric.Text(numberWithCommas(Math.round(d).toString()), {
          ...textConfig,
          originX: "center"
        });
      }

      let superScriptInstance = new fabric.Text("ft", {
        fontSize: 35,
        fontFamily: "Helvetica",
        fill: "white",
        originX: "left",
        left: d > 99999999 ? 385 : numberWithCommas(Math.round(d).toString()).length * 28
      });

      var shadow = {
        color: "rgba(0,0,0,.6)",
        blur: 5,
        offsetX: 3,
        offsetY: 3,
        opacity: 1
      };

      let groupInstance = new fabric.Group([numberInstance, superScriptInstance], {
        left: 1640,
        top: 440,
        originX: "center",
        hasControls: false,
        hasBorders: false
      });

      numberInstance.setShadow(shadow);
      superScriptInstance.setShadow(shadow);

      // let numberInstance = new fabric.Text(
      //   numeral(d)
      //     .format("0.00a")
      //     .toUpperCase(),
      //   textConfig
      // );
      // zoombgCanvas.add(numberInstance);
      zoombgCanvas.add(groupInstance);
      // zoombgCanvas.add(groupInstance);

      fabric.Image.fromURL(image, function(img) {
        img.lockMovementX = true;
        img.lockMovementY = true;
        zoombgCanvas.add(img);
        zoombgCanvas.sendToBack(img);
        cb(zoombgCanvas.toDataURL());
      });

      // zoombgCanvas.add(numberInstance);
    }
  }

  function download(filename, dataurl, selectedName) {
    setSelectedImageUrl(dataurl);
    var link = document.createElement("a");
    link.download = filename;
    link.href = dataurl;
    link.click();
  }

  function renderImageSelection(imageUrl, filename, ctaText, selectedName) {
    return (
      <Grid item xs={12}>
        <Grid item xs={12} style={{ height: "20px" }}></Grid>
        <Grid item xs={12}>
          <img src={imageUrl} alt="background" width="80%" style={{ display: "block", margin: "0 auto" }} />
        </Grid>
        <Grid item xs={12} style={{ height: "20px" }}></Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            className="button"
            onClick={function() {
              download(filename, imageUrl, selectedName);
            }}
          >
            <div style={{ paddingTop: "4px" }}>{ctaText}</div>
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderAllowPage() {
    return (
      <Grid container key={"fdjaifaavca09"}>
        <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
          <Grid item container>
            <Grid item xs={12}>
              <img
                src={"/img/background_examples.jpg"}
                alt="background examples"
                style={{ width: "60%", margin: "0 auto", display: "block" }}
              />
            </Grid>
            <Grid item xs={12} style={{ height: 30 }}></Grid>
            <Grid item xs={12}>
              <h1 className="blue bold home-headline" style={{ width: "50%", display: "block", margin: "0 auto" }}>
                CLICK HIT TO GENERATE THE DISTANCE BETWEEN YOU AND HOME PLATE AT THE WORLD SERIES
              </h1>
            </Grid>
            <Grid item xs={12} style={{ height: 30 }}></Grid>
            <Grid item xs={12}>
              <Button
                onClick={function() {
                  setAllowDistance(true);
                }}
                style={{ display: "block", margin: "0 auto" }}
              >
                <img src="/img/hit_button.png" alt="hit" style={{ display: "block" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderShareButtons() {
    return (
      <Grid container item xs={12}>
        <Grid item xs={3}></Grid>
        <Grid item container xs={6}>
          <Grid item xs={3}></Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <Button onClick={shareTwitter}>
              <img src="/img/twitter_share.png" alt="copy link" style={{ width: "100%" }} />
            </Button>
            <p style={{ fontSize: "10pt", textAlign: "center" }}>Twitter</p>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Button onClick={copyLink}>
              <img src="/img/copy_link.png" alt="copy link" style={{ width: "100%" }} />
            </Button>
            <Popover
              open={popoverStatus}
              anchorEl={anchorElement}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div style={{ padding: 10 }}>Link Copied</div>
            </Popover>
            <p style={{ fontSize: "10pt", textAlign: "center" }}>Copy Link</p>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={shareFacebook}>
              <img src="/img/fb_share.png" alt="facebook" style={{ width: "100%" }} />
            </Button>
            <p style={{ fontSize: "10pt", textAlign: "center" }}>Facebook</p>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    );
  }

  function renderSelectionPage() {
    if (selectedImageUrl === null) {
      return (
        <Grid container direction="row" key={"da9fkdfajoalkdf0"}>
          <Grid item xs={12} style={{ height: 20 }}></Grid>
          <Grid item xs={12}>
            <h1 className="bg-generator-headline" style={{ width: "80%", margin: "0 auto", display: "block" }}>
              <i>CONGRATULATIONS YOUR DISTANCE HAS BEEN GENERATED</i>
            </h1>
          </Grid>
          <Grid item xs={12}>
            <h1 className="bg-generator-headline" style={{ width: "80%", margin: "0 auto", display: "block" }}>
              DOWNLOAD YOUR CUSTOM ZOOM BACKGROUND
            </h1>
          </Grid>
          <Grid item md={6}>
            {renderImageSelection(tb1ImageUrl, "TB_Office_BG.jpg", "Download Rays Zoom Background #1", "Rays_Office")}
          </Grid>
          <Grid item md={6}>
            {renderImageSelection(
              la1ImageUrl,
              "LA_Office_BG.jpg",
              "Download Dodgers Zoom Background #1",
              "Dodgers_Office"
            )}
          </Grid>
          <Grid item md={6}>
            {renderImageSelection(tb2ImageUrl, "TB_Seats_BG.jpg", "Download Rays Zoom Background #2", "Rays_Seats")}
          </Grid>
          <Grid item md={6}>
            {renderImageSelection(la2ImageUrl, "LA_Seats_BG.jpg", "Download Dodgers Zoom Background #2")}
          </Grid>
          <Grid item xs={12} style={{ height: 60 }}></Grid>
          {renderShareButtons()}
          <Grid item xs={12} style={{ height: 60 }}></Grid>
          <canvas id="tbCanvas1" ref={tb1BackgroundCanvas} style={{ width: "100%", display: "none" }}></canvas>
          <canvas id="tbCanvas2" ref={tb2BackgroundCanvas} style={{ width: "100%", display: "none" }}></canvas>
          <canvas id="laCanvas1" ref={la1BackgroundCanvas} style={{ width: "100%", display: "none" }}></canvas>
          <canvas id="laCanvas2" ref={la2BackgroundCanvas} style={{ width: "100%", display: "none" }}></canvas>
        </Grid>
      );
    }
  }

  function renderComplete() {
    if (selectedImageUrl !== null) {
      return (
        <Grid container key={"13ij2390401"}>
          <Grid item xs={12} style={{ height: 40 }}></Grid>
          <Grid item xs={12}>
            <h1 className="bg-generator-headline" style={{ width: "50%", margin: "0 auto", display: "block" }}>
              THANK YOU. WITH YOUR HELP WE’LL #KNOCKALSOUTOFTHEPARK
            </h1>
          </Grid>
          <Grid item xs={12} style={{ height: 40 }}></Grid>
          <Grid item xs={12}>
            <img style={{ display: "block", width: "50%", margin: "0 auto" }} src={selectedImageUrl} alt="completed" />
          </Grid>
          <Grid item xs={12} style={{ height: 40 }}></Grid>
          <Grid item xs={12}>
            <h1 style={{ textAlign: "center" }} className="bg-confirm-text">
              DONATE YOUR DISTANCE
            </h1>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ textAlign: "center" }} className="bg-confirm-text">
              (Suggested amount only. Give what you wish.)
            </h3>
          </Grid>
          <Grid item xs={12} style={{ height: 40 }}></Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button className="button donate-button bold" onClick={handleDonate}>
              Donate{" $"}
              {Math.round(distance)
                .toString()
                .slice(0, 4) / 100}
            </Button>
          </Grid>
          <Grid item xs={12} style={{ height: 40 }}></Grid>
          {renderShareButtons()}
          <Grid item xs={12} style={{ height: 40 }}></Grid>
        </Grid>
      );
    }
  }

  function renderViews() {
    if (allowDistance === false) {
      return renderAllowPage();
    } else if (distance === 0) {
      return (
        <h1
          className="bg-loading-text"
          style={{
            display: "block",
            position: "absolute",
            transform: "translateX(-50%) translateY(-50%)",
            top: "50%",
            left: "50%"
          }}
        >
          <i>PLEASE WAIT WHILE WE GENERATE YOUR DISTANCE</i>
        </h1>
      );
    } else {
      return <div>{selectedImageUrl ? renderComplete() : renderSelectionPage()}</div>;
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item style={{ margin: "0 auto" }}>
          <Grid container direction="column" justify="center" alignItems="center" className={"header"}>
            <img src={header_logo} alt="logo" style={{ width: "70%" }} />
          </Grid>
        </Grid>
      </Grid>
      {renderViews()}
      <input style={{ opacity: 0 }} readOnly value="https://donateals.org" id="copyText" />
    </div>
  );
};

export default BackgroundGenerator;
