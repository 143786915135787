import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../contexts/ApplicationContext";
import Location from "../utils/Location";
import { fabric } from "fabric-with-gestures";
import CanvasHelper from "../utils/Canvas";
import mlb_logo from "../..//images/mlb_logo.png";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const EditImage = () => {
  const canvasRef = React.useRef(null);
  const [application, setApplication] = useContext(ApplicationContext);

  function retake() {
    setApplication({ ...application, page: "capture" });
  }

  function confirm() {
    let imageURI = canvasRef.current.toDataURL("image/jpeg", 1.0);
    let imageBuffer = canvasRef.current
      .getContext("2d")
      .getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    setApplication({ ...application, completeImageURI: imageURI, completeImageBuffer: imageBuffer, page: "confirm" });
  }

  useEffect(() => {
    let canvas = new fabric.Canvas(canvasRef.current, { preserveObjectStacking: true });
    let CanvasHelperInstance = new CanvasHelper(canvas);
    CanvasHelperInstance.setup();

    let locationInstance = new Location();

    let dist = locationInstance.calculateDistance(
      application.location.lat,
      application.location.lon,
      application.team.lat,
      application.team.lon
    );

    CanvasHelperInstance.draw(application.image, Math.round(dist));
  }, []);

  return (
    <div className="edit-image-container">
      <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{ height: "100%" }}>
        <Grid item className={"header"}>
          <Grid container direction="column" justify="center" alignItems="center">
            <img src={mlb_logo} alt="logo" style={{ height: "8vh", padding: "1vh" }} />
          </Grid>
        </Grid>
        <Grid item>
          <canvas id="editor" ref={canvasRef}></canvas>
        </Grid>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item>
              <p className="proxima-bold edit-image-copy" style={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 0 }}>
                Scale &amp; position number &amp; photo before confirming
              </p>
            </Grid>
            <Grid item>
              <Button className="button bold edit-button" onClick={confirm}>
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button className="button bold edit-button" onClick={retake}>
                Retake
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div className="disclaimer-footer black">
          <p>MLB trademarks and copyrights are used with permission of Major League Baseball. Visit MLB.com.</p>
        </div>
      </Grid>
    </div>
  );
};

export default EditImage;
