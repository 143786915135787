import { fabric } from "fabric-with-gestures";
import numeral from "numeral";

function CanvasHelper(canvasInstance) {
  let publicAPI = {
    setup,
    draw
  };
  return publicAPI;

  function setup() {
    canvasInstance.setWidth(window.innerWidth);
    canvasInstance.setHeight(window.innerWidth / 1.7);
    // canvasInstance.setHeight(window.innerHeight / 2);
    canvasInstance.setBackgroundColor("black");
  }

  function draw(image, text) {
    loadImage(image, image => {
      fabric.Image.fromURL(image, function(img) {
        img
          .set({
            left: 0,
            top: 0,
            // angle: -90,
            // flipX: true,
            // originY: "top",
            // originX: "right",
            hasControls: false,
            hasBorders: false
          })
          .scaleToWidth(window.innerWidth);

        canvasInstance.add(img).setActiveObject(img);

        if (text) {
          drawText(text);
        }
      });
    });
  }

  function drawText(text) {
    let textConfig = {
      fontSize: 50,
      fontFamily: "Helvetica",
      fill: "white",
      hasControls: false,
      hasBorders: false
    };

    let numberInstance;

    if (text > 999999) {
      numberInstance = new fabric.Text(
        numeral(text)
          .format("0.00a")
          .toUpperCase(),
        { ...textConfig, originX: "right" }
      );
    } else {
      numberInstance = new fabric.Text(numberWithCommas(text.toString()), { ...textConfig, originX: "right" });
    }

    let superScriptInstance = new fabric.Text(" ft", {
      fontSize: 25,
      fontFamily: "Helvetica",
      fill: "white"
    });

    var shadow = {
      color: "rgba(0,0,0,.6)",
      blur: 5,
      offsetX: 3,
      offsetY: 3,
      opacity: 1
    };

    let groupInstance = new fabric.Group([numberInstance, superScriptInstance], {
      left: 50,
      top: 50,
      hasControls: false,
      hasBorders: false
    });

    numberInstance.setShadow(shadow);
    superScriptInstance.setShadow(shadow);

    canvasInstance.add(groupInstance);
  }

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  function loadImage(image, cb) {
    let reader = new FileReader();
    reader.onload = function(e) {
      cb(e.target.result);
    };
    reader.readAsDataURL(image);
  }
}

export default CanvasHelper;
