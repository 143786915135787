import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import slide1bg from "../../images/slide1_bg.jpg";
import slide2bg from "../../images/slide2_bg.jpg";
import slide3bg from "../../images/slide3_bg.jpg";
import slide4bg from "../../images/slide4_bg.jpg";
import slide5bg from "../../images/slide5_bg.jpg";
import slide6bg from "../../images/slide6_bg.jpg";
import slide7bg from "../../images/slide7_bg.jpg";
import Button from "@material-ui/core/Button";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// import { autoPlay } from "react-swipeable-views-utils";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    imgPath: slide1bg
    // label: "ALS",
    // headline: "KNOCK ALS OUT OF THE PARK",
    // paragraph:
    //   "ALS is a debilitating neurological disease. Join us and our partners at Major League Baseball – as we strive towards a cure.",
    // highlight: "Together, let’s knock ALS out of the park."
  },
  {
    imgPath: slide2bg
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1024,
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    display: "block",
    maxWidth: 1024,
    maxHeight: 500,
    overflow: "hidden",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%"
  }
}));

function Carousel() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }
  function handlePageChange() {
    window.open(
      "https://secure2.convio.net/alsa/site/Donation2;jsessionid=00000000.app294b?df_id=37513&mfc_pref=T&37513.donation=form1&NONCE_TOKEN=3A428FA3A144302E8483FF0EA48912D8"
    );
  }
  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews axis={"x"} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
        {tutorialSteps.map((step, index) => (
          <div key={Math.random(0, 1000)}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div
                className={classes.img}
                style={{ backgroundImage: "url(" + step.imgPath + ")" }}
                key={step.label}
                // alt={step.label}
              >
                <div className="carousel-inner">
                  <div className="carousel-copy-container">
                    <h1 className="carousel-headline" style={{ opacity: index === 0 ? 1 : 0 }}>
                      {step.headline}
                    </h1>
                    <p className="carousel-paragraph" style={{ color: index === 0 ? "black" : "white" }}>
                      {step.paragraph}
                    </p>
                    <p className="carousel-highlight" style={{ color: index === 0 ? "black" : "white" }}>
                      {step.highlight}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        variant="dots"
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}

export default Carousel;
