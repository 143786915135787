function Location() {
  var publicAPI = {
    getLocation,
    calculateDistance
  };
  return publicAPI;

  function getLocation(cb) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          cb(pos);
        },
        err => {
          // alert(err.message);
          alert("Please enable location services in your device settings to use this site");
        }
      );
    } else {
      return { message: "not supported" };
    }
  }

  function calculateDistance(lat1, lon1, lat2, lon2, unit) {
    //haversine formula
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === "K") {
        dist = dist * 1.609344;
      }
      if (unit === "N") {
        dist = dist * 0.8684;
      }
      return dist * 5280;
    }
  }
}

export default Location;
