import React, { useContext, useState } from "react";
import home_plates from "../../data/home_plates";
import ApplicationContext from "../../contexts/ApplicationContext";
import { NativeSelect } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// import Storage from "../utils/Storage";
// import config from "../../config";
import CountUp from "react-countup";

const TeamSelect = () => {
  // const StorageInstance = new Storage();
  const [application, setApplication] = useContext(ApplicationContext);
  const [teamImage, setTeamImage] = useState("angels_logo.png");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [submit, setSubmit] = useState(false);

  // document.getElementsByTagName('body').backgroundImage =

  function onSubmit(event) {
    event.preventDefault();
    const selectedValue = event.target.selection.value;
    //set app state
    setApplication({
      ...application,
      team: {
        name: selectedValue,
        lon: home_plates[selectedValue].lon,
        lat: home_plates[selectedValue].lat
      }
      // page: "capture"
    });
    // console.log(event.target.selection.value);
    setSubmit(true);
    //set storage
    // if (config.useStorage) {
    //   StorageInstance.setItem("team", selectedValue);
    //   StorageInstance.setItem("teamLat", home_plates[selectedValue].lat);
    //   StorageInstance.setItem("teamLon", home_plates[selectedValue].lon);
    // }
  }

  function onChangeTeam(e) {
    setTeamImage(home_plates[e.target.value].image);
    setSelectedTeam(e.target.value);
    // console.log(Object.keys(home_plates).sort());
  }

  function renderForm() {
    return (
      <div id="teamSelectContainer">
        <Grid container direction={"row"}>
          <form onSubmit={onSubmit} id="teamSelect" style={{ width: "100%" }}>
            <Grid item>
              <p className="label normalize proxima-bold">FROM:</p>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="space-around" alignItems="center">
                <Grid item xs={9}>
                  <NativeSelect
                    value={selectedTeam}
                    id="selection"
                    name="team"
                    style={{ width: "100%" }}
                    onChange={e => {
                      onChangeTeam(e);
                    }}
                    inputProps={{ "aria-label": "age", color: "#062c3f" }}
                  >
                    {Object.keys(home_plates).sort().map(plate => (
                      <option key={plate}>{plate}</option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid item xs={3}>
                  <Grid container direction="row" alignItems={"center"} justify={"center"}>
                    <Grid item>
                      <img
                        width="80%"
                        style={{ marginLeft: "20%" }}
                        display="block"
                        id="teamImage"
                        alt="teamimage"
                        src={require(`../../images/logos/${teamImage}`)}
                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div id="teamSelectSubmitContainer">
                <p className="label normalize proxima-bold">TO:</p>
                <div className="left">
                  <p className="yourLocation normalize proxima-bold">Current Location</p>
                </div>
                <div className="right">
                  <div className="inputContainer">
                    <input className="proxima-bold" type="submit" value="HIT"></input>
                  </div>
                </div>
              </div>
            </Grid>
          </form>
        </Grid>
      </div>
    );
  }

  return (
    <div className="team-select">
      <div className="counter-container">
        {submit === true ? (
          <CountUp
            separator=","
            className="counter"
            start={100000}
            end={100000000}
            duration={2}
            suffix={"ft".sup()}
            onEnd={() => setApplication({ ...application, page: "capture" })}
          />
        ) : (
          <p className="counter"></p>
        )}
      </div>
      {renderForm()}
      <div className="footer">
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item xs={6}>
            <img
              style={{ width: "90%", marginLeft: "10%" }}
              alt="hashtag logo"
              src={require("../../images/hashtag_logo.png")}
            />
          </Grid>
          <Grid item xs={6}>
            <img
              style={{ width: "45%", marginLeft: "45%" }}
              alt="als logo"
              src={require("../../images/mlb_logo_red.png")}
            />
          </Grid>
          <Grid item>
            <p className="disclaimer-inline">
              MLB trademarks and copyrights are used with permission of Major League Baseball. Visit MLB.com.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TeamSelect;
