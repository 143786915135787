function APIHelper() {
  var publicAPI = {
    post
  };

  return publicAPI;

  function post(image) {
    return new Promise(function(resolve, reject) {
      // var url = "http://localhost:9000/upload.php";
      // var url = "https://localhost/upload.php";
      var url = "https://donateals.org/upload.php";
      const formData = new FormData();

      formData.append("image", dataURLtoBlob(image));

      const options = {
        method: "POST",
        body: formData
      };

      fetch(url, { method: "POST", body: formData })
        .then(res => res.json())
        .then(response => resolve(response))
        .catch(err => {
          console.log(err);
          // reject(err);
        });
    });
  }

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}

export default APIHelper;
