import React, { useState } from "react";
import ApplicationContext from "../contexts/ApplicationContext";
import config from "../config";
import Storage from "./utils/Storage";
import RenderPages from "./RenderPages";

const Generator = () => {
  const StorageInstance = new Storage();

  const application = useState({
    location: {
      lat: config.useStorage ? StorageInstance.getItem("userLat", 0) : 0,
      lon: config.useStorage ? StorageInstance.getItem("userLon", 0) : 0
    },
    team: {
      name: config.useStorage ? StorageInstance.getItem("team", null) : null,
      lat: config.useStorage ? StorageInstance.getItem("teamLat", 0) : 0,
      lon: config.useStorage ? StorageInstance.getItem("teamLon", 0) : 0
    },
    image: new File([], "defaultFile"),
    completeImageURI: "",
    page: "locate"
  });

  return (
    <div id="generator">
      <ApplicationContext.Provider value={application}>
        <RenderPages />
      </ApplicationContext.Provider>
    </div>
  );
};
export default Generator;
