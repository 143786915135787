import React, { useState } from "react";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

const Instructions = () => {
  const [activeStep, setActiveStep] = useState(0);
  const tutorialSteps = [
    { identifier: "onboarding_1" },
    { identifier: "onboarding_2" },
    { identifier: "onboarding_3" },
    { identifier: "onboarding_4" }
  ];

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <div>
      {/* <MobileStepper index={0} enableMouseEvents style={{ backgroundColor: "rgba(0,0,0,.4)" }} */}
      <SwipeableViews axis={"x"} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
        {tutorialSteps.map((step, index) => (
          <div key={Math.random(0, 1000)}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div
                id={tutorialSteps[activeStep].identifier}
                style={{
                  width: "100vw",
                  height: "90vh",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={4}
        position="static"
        variant="dots"
        activeStep={activeStep}
        style={{ backgroundColor: "#062c3f" }}
        nextButton={
          <Button size="small" onClick={handleNext} style={{ color: "white" }} disabled={activeStep === 4 - 1}>
            Next
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} style={{ color: "white" }} disabled={activeStep === 0}>
            Back
          </Button>
        }
      />
    </div>
  );
};

{
  /* <div style={{ width: "100%", height: "100%" }} id={1}>
          <div className="onboarding" id="onboarding_1"></div>
        </div>
        <div style={{ height: "100%", width: "100%" }} id={2}>
          <div className="onboarding" id="onboarding_2"></div>
        </div>
        <div style={{ height: "100%", width: "100%" }} id={3}>
          <div className="onboarding" id="onboarding_3"></div>
        </div>
        <div style={{ height: "100%", width: "100%" }} id={4}>
          <div className="onboarding" id="onboarding_4"></div>
        </div> */
}

export default Instructions;
