import React, { useContext } from "react";
import ApplicationContext from "../contexts/ApplicationContext";
import ImageCapture from "./routes/ImageCapture";
import EditImage from "./routes/EditImage";
import TeamSelect from "./routes/TeamSelect";
import Confirm from "./routes/Confirm";
import Locate from "./routes/Locate";

const RenderPages = () => {
  const [application] = useContext(ApplicationContext);

  function renderPage() {
    switch (application.page) {
      case "confirm":
        return <Confirm />;
      case "edit":
        return <EditImage />;
      case "capture":
        return <ImageCapture />;
      case "team":
        return <TeamSelect />;
      default:
        return <Locate />;
    }
  }

  return renderPage();
};

export default RenderPages;
